import axios from "axios";
import { URLS } from "./urls";

export const getAllAppointments = (projectId, startDateTime, endDateTime, buildingId) => axios.get(URLS.GET_ALL_APPOINTMENTS + projectId, {
  params: {
    buildingId,
    startDateTime,
    endDateTime,
  }
});
export const updateAgendaView = (agendaView) => axios.patch(URLS.UPDATE_AGENDA_VIEW, null, { params: { agendaView } });

export const getAgendaView = () => axios.get(URLS.GET_AGENDA_VIEW)
export const getSearchAddress = (searchTerm) => axios.get(URLS.GET_SEARCH_ADDRESS, { params: { searchTerm } })
export const getCategories = () => axios.get(URLS.GET_CATEGORIES)
export const getSlotsGenerationDetails = (generationId) => axios.get(URLS.GET_SLOTS_GENERATION_DETAILS + generationId)
export const generateSlots = (slotsData) => axios.post(URLS.GENERATE_SLOTS, slotsData)
export const finalizeGeneratedSlots = (generationId) => axios.patch(URLS.FINALIZE_GENERATION_SLOTS + generationId, null)
export const cancelAppointment = (data) => axios.patch(URLS.CANCEL_APPOINTMENT, data)
export const confirmAppointment = ({slotId, buildingId}) => axios.patch(URLS.CONFIRM_APPOINTMENT, null,{params:{slotId,buildingId}})
export const updateOnlineMeetingLink = ({ slotId, link }) => axios.patch(URLS.UPDATE_MEETING_LINK, null, { params: { slotId, link } })
export const deleteSlots = ({ slotId, allFutureSlots, allPastSlots }) => axios.delete(URLS.DELETE_SLOTS + slotId, { params: { allFutureSlots, allPastSlots } })
export const getBuildingsForSlot = (slotId) => axios.get(URLS.GET_AVAILABLE_BUILDINGS_FOR_SLOT + slotId)
export const getSlotsGenerations = (projectId) => axios.get(URLS.GET_SLOTS_GENERATIONS + projectId)
export const getBuildingsInfoForGeneration = (generationId) => axios.get(URLS.GET_BUILDINGS_INFO_FOR_GENERATION + generationId)
export const addBuildingsToGeneration = ({generationId, buildingIds}) => axios.patch(URLS.ADD_BUILDINGS_TO_GENERATION + generationId, buildingIds)
