const { webApiUrl } = window.appConfig;

export const URLS = {

    /*Dossiers Apis*/
    GET_DRAWING_DOSSIERS: webApiUrl + "api/Dossier/GetDrawingDossier/",
    GET_ALL_DOSSIERS_BY_PROJECT_ID: webApiUrl + "api/Dossier/GetDossiersListByProjectId/",
    GET_ALL_DOSSIERS_BY_BUILDING_ID: webApiUrl + "api/Dossier/GetDossiersListByBuildingId/",
    GET_ALL_DOSSIERS: webApiUrl + "api/Dossier/GetAllDossiersByProjectId/",
    GET_DOSSIER_BUILDING_INFO: webApiUrl + "api/Dossier/GetDossierBuildingInfo/",
    GET_DOSSIER_GENERAL_INFO: webApiUrl + "api/Dossier/GetDossierGeneralInfo/",
    GET_USERS_FOR_DOSSIER_SHARE: webApiUrl + "api/Dossier/GetUsersForDossierDeadline/",
    GET_AVAILABLE_USERS_ROLE_BY_PROJECT_ID: webApiUrl + "api/Dossier/GetAvailableUsersAndRolesByProjectId/",
    UPDATE_DOSSIER_RIGHTS: webApiUrl + "api/Dossier/UpdateDossierUserRights/",
    UPLOAD_DOSSIER_FILES: webApiUrl + "api/Dossier/UploadDossierFiles/",
    ADD_DOSSIER: webApiUrl + "api/Dossier/CreateOrUpdateDossier/",
    UPDATE_DOSSIER_INFORMATION: webApiUrl + "api/Dossier/UpdateDossierDataByKey/",
    DELETE_DOSSIER: webApiUrl + "api/Dossier/DeleteDossier/",
    SEND_DOSSIER_NOTIFICATION: webApiUrl + "api/Dossier/DossierNotifications/",
    GET_EXISTING_IMAGES_FOR_PROJECTS: webApiUrl + "api/Dossier/GetExistingFilesForProject/",
    GET_EXISTING_IMAGES_FROM_REPAIR_REQUEST: webApiUrl + "api/WB/GetExistingImagesFromRepairRequest/",
    GET_BACKGROUND_IMAGE: webApiUrl + "api/Dossier/GetBackgroundImage/",
    GET_UPLOADED_DOSSIER_IMAGE: webApiUrl + "api/Dossier/GetUploadedDossierFile/",
    GET_UPLOADED_DOSSIER_FILE_THUMBNAIL: webApiUrl + "api/Dossier/GetFileThumbnail/",
    GET_ATTACHMENT: webApiUrl + "api/Home/GetAttachment/",
    GET_ATTACHMENT_THUMBNAIL: webApiUrl + "api/Home/GetAttachmentThumbnail/",
    UPDATE_DOSSIER_BUILDING_DATA_BY_KEY: webApiUrl + "api/Dossier/UpdateBuildingDossierDataKeyValue/",
    LINK_FILE_TO_DOSSIER: webApiUrl + "api/Dossier/LinkFilesToDossier/",
    UPDATE_DOSSIER_BUILDING: webApiUrl + "api/Dossier/UpdateDossierBuildings/",
    UPDATE_DOSSIER_BUILDING_STATUS: webApiUrl + "api/Dossier/UpdateDossierBuildingStatus/",
    MOVE_DOSSIER_FILES: webApiUrl + "api/Dossier/MoveDossierBuildingsFiles/",
    UPLOAD_BACKGROUND_IMAGE: webApiUrl + "api/Dossier/UploadBackgroundImage/",
    GET_BUILDING_LIST_WITH_DOSSIERS: webApiUrl + "api/Dossier/GetBuildingListWithDossiers/",
    UPDATE_DOSSIER_LAST_VIEW: webApiUrl + "api/Dossier/UpdateDossierLastView/",
    CREATE_DOWNLOAD_ZIP: webApiUrl + "api/Dossier/CreateZipForDownload/",
    DOWNLOAD_ZIP_FILE: webApiUrl + "api/Dossier/DownloadZip/",
    ORDER_DOSSIERS: webApiUrl + "api/Dossier/OrderDossier/",
    UPDATE_DOSSIER_DEADLINE: webApiUrl + "api/Dossier/UpdateDossierDeadline/",
    GET_DRAWING_FILES: webApiUrl + "api/Dossier/GetDrawingFiles/",
    GET_DOSSIER_PDF_IMAGE: webApiUrl + "api/Dossier/GetDossierPdfImage/",
    PIN_UNPIN_DOSSIER_PROJECT: webApiUrl + "api/Dossier/PinUnpinProjectForDossiers/",
    GET_PINNED_DOSSIER_PROJECTS: webApiUrl + "api/Dossier/GetPinnedProjects",

    /*Surveying Apis*/
    GET_AVAILABLE_SURVEY_MODULES: webApiUrl + 'api/Survey/GetAvailableSurveyModules/',
    MAP_BUILDING_TO_OBJECT: webApiUrl + 'api/WB/MapBuildingToWbObject/',
    GET_SURVEYS_MOMENTS: webApiUrl + 'api/WB/GetAllSurveyingMomentsByProjectId/',
    GET_SURVEY_MOMENT_INFO: webApiUrl + 'api/WB/GetSurveyingMomentObjectDetails/',
    GET_ASSIGNMENT_DETAILS: webApiUrl + 'api/WB/GetAssignmentDetails/',
    GET_OBJECTS_LIST_BY_PROJECT_ID: webApiUrl + 'api/Survey/GetAvailableObjects/',
    GET_ORGANIZATIONS: webApiUrl + 'api/Organisation/GetOrganisations',
    GET_ORGANIZATIONS_BY_PROJECT: webApiUrl + 'api/Organisation/GetOrganisationsByProject/',
    UPDATE_RESPONSIBLE_PERSON: webApiUrl + 'api/WB/UpdateResponsiblePerson',
    COMPLETE_SURVEYING_MOMENTS: webApiUrl + 'api/WB/CompleteSurveyingMoments',
    INTERNAL_COMPLETE_SURVEYING_MOMENTS: webApiUrl + 'api/WB/InternalCompleteSurveyingMoments',
    UPDATE_RESPONSIBLE_PERSON_FOR_ASSIGNMENTS: webApiUrl + 'api/WB/UpdateAssignedPerson/',
    UPLOAD_ASSIGNMENT_FILES: webApiUrl + 'api/WB/UploadAssignmentFiles',
    UPDATE_ASSIGNMENT_INFO: webApiUrl + 'api/WB/UpdateAssignmentInfo/',
    ADD_REPAIR_REQUEST: webApiUrl + 'api/WB/AddRepairRequest/',
    GET_ASSIGNMENT_MESSAGES: webApiUrl + 'api/WB/GetAssignmentMessages/',
    GET_ALL_OBJECTS: webApiUrl + 'api/WB/getObjectsForProjects/',
    GET_ALL_PROJECTS: webApiUrl + 'api/WB/getProjects/',
    GET_INSPECTIONS: webApiUrl + 'api/Survey/GetSurveys',
    GET_INSPECTION: webApiUrl + 'api/Survey/GetSurvey/',
    GET_REPAIR_REQUESTS: webApiUrl + 'api/RepairRequest/GetRepairRequestsForSurvey/',
    GET_REPAIR_REQUEST_DETAILS: webApiUrl + 'api/RepairRequest/GetRepairRequestDetails/',
    GET_REPAIR_REQUEST_ATTACHMENTS: webApiUrl + 'api/RepairRequest/GetImage/',
    GET_REPAIR_REQUEST_LOCATIONS: webApiUrl + 'api/RepairRequest/GetRepairRequestLocations',
    GET_PROJECT_LOGO_FOR_REPORT: `${webApiUrl}api/home/ProjectLogo/`,
    GET_REPAIR_REQUEST_TYPE_LIST: webApiUrl + 'api/RepairRequest/GetRepairRequestTypeList',
    GET_REPAIR_REQUEST_CAUSE_LIST: webApiUrl + 'api/RepairRequest/GetRepairRequestCauseList',
    GET_PIN_LOCATION_IMAGE: webApiUrl + 'api/Survey/GetPinLocationImage/',
    GET_REPAIR_REQUEST_CAUSER_LIST: webApiUrl + 'api/RepairRequest/GetRepairRequestCauserList',
    GET_REPAIR_REQUEST_CARRY_OUT_TYPE_LIST: webApiUrl + 'api/RepairRequest/GetRepairRequestCarryOutAsTypeList',
    GET_REPAIR_REQUEST_NATURE_LIST: webApiUrl + 'api/RepairRequest/GetRepairRequestNatureList',
    ADD_SURVEY_REPAIR_REQUEST: webApiUrl + 'api/repairRequest/AddRepairRequest',
    GET_SURVEY_REPORT: webApiUrl + 'api/Survey/GetSurveyReport/',
    GET_SURVEY_REPORT_FOR_SECOND_SIGNATURE: webApiUrl + 'api/Survey/GetSurveyReportForSecondSignature/',
    COMPLETE_SIGNATURE: webApiUrl + 'api/Survey/CompleteSecondSignature/',
    UPDATE_SURVEY: webApiUrl + 'api/Survey/AddOrUpdateSurvey',
    START_SECOND_SIGNATURE: webApiUrl + 'api/Survey/StartSecondSignature/',
    GET_RECIPIENTS_EMAILS: webApiUrl + 'api/Survey/GetRecipientsForDeliveryEmail/',
    GET_EMAILS_FOR_REPAIR_REQUEST: webApiUrl + 'api/RepairRequest/GetEmailsForRepairRequest/',
    SEND_RECIPIENTS_EMAILS: webApiUrl + 'api/Survey/SendDeliveryEmail/',
    SEND_RECIPIENTS_EMAILS_FOR_SECOND_SIGNATURE: webApiUrl + 'api/Survey/SendSecondSignatureEmail/',
    GET_PRODUCTS_SERVICES: webApiUrl + 'api/Home/GetProductServices',
    EXISTING_IMAGES_FROM_REPAIR_REQUEST: webApiUrl + 'api/WB/GetExistingImagesFromRepairRequest/',
    UPDATE_MOMENT_LAST_VIEW: webApiUrl + 'api/WB/UpdateSurveyingMomentLastView/',
    DELETE_ASSIGNMENT_IMAGE: webApiUrl + 'api/WB/DeleteAssignmentFile/',
    INTERNAL_COMPLETE_ASSIGNMENTS: webApiUrl + 'api/WB/InternalCompleteAssignments',
    COMPLETE_ASSIGNMENTS: webApiUrl + 'api/WB/CompleteAssignments',
    CREATE_REPAIR_REQUEST: webApiUrl + 'api/repairRequest/AddRepairRequest',
    GET_ASSIGNMENT_ATTACHMENT: webApiUrl + 'api/WB/GetAssignmentAttachment/',
    GET_SETTLEMENT_TERM: webApiUrl + 'api/RepairRequest/GetSettlementTerm',
    UPDATE_REPAIR_REQUEST: webApiUrl + "api/RepairRequest/UpdateRepairRequestByKey/",
    ADD_REPAIR_REQUEST_RESOLVER: webApiUrl + "api/RepairRequest/AddRepairRequestResolver/",
    APPLICATION_MANAGER_ACTION_ITEMS: webApiUrl + "api/WB/GetApplicationManagerActionItems",
    CONFIRM_PROJECT_LINKING: webApiUrl + "api/WB/ConfirmProjectLinking",
    LINK_EMAIL_TO_LOGIN: webApiUrl + "api/WB/LinkEmailToLogin",
    RESET_ASSIGNMENT_STATUS: webApiUrl + "api/WB/ResetStatusForAssignments",
    GET_PROJECTS_FOR_LINKING: webApiUrl + "api/WB/GetProjectsForLinking",
    GET_ASSIGNMENT_ATTACHMENT_THUMBNAIL: webApiUrl + "api/WB/GetAssignmentAttachmentThumbnail/",
    GET_WB_PROJECT_INFO: webApiUrl + "api/WB/GetWBProjectInfoByHuisinfoProjectId/",
    GET_WORK_ORDER_REPORT_PDF: webApiUrl + "api/RepairRequest/GetWorkorderReport/",
    ADD_OR_UPDATE_DRAWING_LOCATION_NAME: webApiUrl + "api/Dossier/AddOrUpdateDrawingLocationName",
    GET_DRAWING_LOCATION_NAME: webApiUrl + "​api/Dossier/GetDrawingLocationNames",
    DELETE_DRAWING_LOCATION_NAME: webApiUrl + "api/Dossier/DeleteDrawingLocationName/",
    ADD_UPDATE_REWORK: webApiUrl + "api/RepairRequest/UpdateRework",
    MARK_COMPLETE_RR: webApiUrl + "api/RepairRequest/MarkCompletedByBuyer",
    DELETE_RR: webApiUrl + "api/RepairRequest/DeleteRepairRequest/",
    GET_REPAIR_REQUEST_DASHBOARD_COUNTS: webApiUrl + "api/RepairRequest/GetRepairRequestDashboardCount",
    LOCK_UNITS: webApiUrl + "api/Home/GetLocks",
    RELEASE_UNITS_LOCKS: webApiUrl + "api/Home/ReleaseLocks",
    GET_ASSIGNMENTS_BY_PROJECT_ID: webApiUrl + "api/WB/GetAssignmentsByProjectId/",
    GET_AVAILABLE_EXECUTORS_FOR_SURVEY: webApiUrl + "api/Survey/GetAvailableExecutorsForSurvey",
    UPDATE_EXECUTOR_FOR_SURVEY: webApiUrl + "api/Survey/UpdateExecutor",

    // profile
    USER_PROFILE: webApiUrl +  "api/Profile/UserProfile",
    ORGANISATION_PROFILE: webApiUrl +  "api/Profile/OrganisationProfile",
    GET_PERSON_TITLE_FOR:webApiUrl +  "api/Profile/GetPersonTitleFor",
    GET_PERSON_MIDDLE_NAMES:webApiUrl + "api/Profile/GetPersonMiddleNames",
    GET_PERSON_TITLE_BEHIND:webApiUrl +  "api/Profile/GetPersonTitleBehind",
    GET_BANKS:webApiUrl + "api/Profile/GetBanks",
    GET_INDUSTRIES :webApiUrl +  "api/Profile/GetIndustries",


    //Aftercare
    GET_AFTERCARE_REPAIR_REQUESTS: webApiUrl + "api/RepairRequest/GetRepairRequests",
    GET_AFTERCARE_WORK_ORDERS: webApiUrl + "api/RepairRequest/GetWorkOrders",
    GET_REPAIR_REQUESTS_STATUS: webApiUrl + "api/RepairRequest/GetRepairRequestStatusList",
    CREATE_WORKORDER_REPORTS_ZIP: webApiUrl + "api/RepairRequest/CreateWorkorderReportsZip",
    DOWNLOAD_EXCEL_TEMPLATE_FOR_IMPORT :  webApiUrl + "api/ImportExport/GetExcelFile",
    VALIDATE_EXCEL_FOR_IMPORT :  webApiUrl + "api/ImportExport/ValidateImportFile",
    JSON_AVAILABLE_FOR_IMPORT :  webApiUrl + "api/ImportExport/JsonFileAvailableToImport",
    PROCEED_TO_IMPORT: webApiUrl + "api/ImportExport/ProceedToImport",

    // Agenda
    GET_ALL_APPOINTMENTS: webApiUrl + "api/Agenda/GetAllAppointments/",
    GET_AGENDA_VIEW: webApiUrl + "api/Agenda/GetAgendaView",
    UPDATE_AGENDA_VIEW: webApiUrl + "api/Agenda/UpdateAgendaView",
    GET_CATEGORIES: webApiUrl + "api/Agenda/GetCategories",
    GENERATE_SLOTS: webApiUrl + "api/Agenda/GenerateSlots",
    GET_SLOTS_GENERATION_DETAILS: webApiUrl + "api/Agenda/GetSlotsGenerationDetails/",
    FINALIZE_GENERATION_SLOTS: webApiUrl + "api/Agenda/FinalizeGeneratedSlots/",
    CANCEL_APPOINTMENT: webApiUrl + "api/Agenda/CancelAppointment",
    CONFIRM_APPOINTMENT: webApiUrl + "api/Agenda/ConfirmAppointment",
    UPDATE_MEETING_LINK: webApiUrl + "api/Agenda/UpdateOnlineMeetingLink",
    DELETE_SLOTS: webApiUrl + "api/Agenda/DeleteSlot/",
    GET_SEARCH_ADDRESS: webApiUrl + "api/Home/SearchAddresses/",
    GET_AVAILABLE_BUILDINGS_FOR_SLOT: webApiUrl + "api/Agenda/GetAvailableBuildingsForSlot/",
    GET_SLOTS_GENERATIONS: webApiUrl + "api/Agenda/GetSlotsGenerations/",
    GET_BUILDINGS_INFO_FOR_GENERATION: webApiUrl + "api/Agenda/GetAllBuildingsInfoForGeneration/",
    ADD_BUILDINGS_TO_GENERATION: webApiUrl + "api/Agenda/AddBuildingsToGeneration/",
    // BuyersGuide
    GET_PROJECTS_INFO_FOR_OVERVIEW: webApiUrl + "api/Home/GetProjectsInfo",

    // contract
    GET_ALL_CONTRACTS : webApiUrl + "api/Contract/GetAllContracts",
    GET_ALL_TEMPLATES : webApiUrl + "api/Contract/GetAllTemplates",
    GET_TEMPLATE_PREVIEW : webApiUrl + "api/Contract/GetTemplatePreview/",
    CREATE_CONTRACT : webApiUrl + "api/Contract/CreateContract",
    GET_CONTRACT_DETAILS : webApiUrl + "api/Contract/GetContractDetails/",
    UPDATE_CONTRACT_KEY_VALUE : webApiUrl + "api/Contract/UpdateContractKeyValue/",
    GET_CONTRACT_DRAFT_AMD_UPDATE_STATUS:  webApiUrl + "api/Contract/GetContractDraftAndUpdateStatus/",
    UPDATE_CONTRACT_DRAFT:  webApiUrl + "api/Contract/UpdateDraft/",
    SEND_FOR_DIGITAL_SIGNING:  webApiUrl + "api/Contract/SendForDigitalSigning",
    GET_SCRIVE_DOC_HOSTORY:  webApiUrl + "api/Contract/GetScriveDocHistory/",
    CONTRACT_SEND_REMINDER:  webApiUrl + "api/Contract/SendReminder/",
    DELETE_CONTRACTS:  webApiUrl + "api/Contract/DeleteContracts",
    SET_PROLONG:  webApiUrl + "api/Contract/Prolong/",
    CANCEL_CONTRACT:  webApiUrl + "api/Contract/Cancel/",
    GET_CONTRACT_PREVIEW: webApiUrl + "api/Contract/GetContractPreview/",

    //  finance
    GET_FINANCE_FOR_BUILDING: webApiUrl + "api/Contract/GetFinanceForBuilding/",
    UPDATE_FINANCE : webApiUrl + "api/Contract/UpdateFinance/",
};
