import {
  Chip,
  CircularProgress,
  Divider,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
  MenuList,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import withStyles from "@mui/styles/withStyles";
import { alpha, Grid, Typography, Popover } from "@mui/material";
import { Paper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { red } from "@mui/material/colors";
import {
  CloudDownload,
  Event,
  PictureAsPdf,
  Send,
  Visibility,
} from "@mui/icons-material";
import { pdf } from "@react-pdf/renderer";
import React, { useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { URLS } from "../../../apis/urls";
import DocumentViewer from "../../../components/DocumentViewer";
import clsx from "clsx";
import {
  formatDate,
  getMimeTypefromString,
  useCanLockedInspection,
  SurveyType,
  useInternetStatusDetector,
} from "../../../_helpers";
import {
  isReadOnly,
  SurveyStatus,
  isCompletedSurvey,
} from "../../../_helpers/enumsHelper";
import { usePDFReport } from "./PDFReport";
import UserInformation from "./UserInformation";
import { MobileDatePicker } from "@mui/x-date-pickers"
import { surveyActions } from "../../../_actions";
import { Tooltip } from "@mui/material";
import { apps, userAccountTypeConstants } from "../../../_constants";

const StyledMenuItem = withStyles((theme) => ({
  root: {
    whiteSpace: "pre-wrap",
    padding: theme.spacing(1, 0),
    "& div": {
      minWidth: 40,
      color: theme.palette.grey[700],
    },
    "& span": {
      color: theme.palette.grey[700],
      fontSize: 12,
    },
  },
}))(MenuItem);

const DASHBOARD_KEYS = [
  {
    items: [
      {
        title: "general.buildingNumber",
        key: "buildingNoIntern",
        event: false,
      },
      {
        title: "general.date",
        key: "date",
        event: false,
        Icon: Event,
      },
    ],
  },
  {
    items: [
      { title: "general.objectNumber", key: "buildingNoExtern" },
      { title: "general.status", key: "status", event: false },
    ],
  },
  {
    items: [
      { title: "general.buyer1", key: "buyerRenter1" },
      { title: "general.buyer2", key: "buyerRenter2" },
    ],
  },
  {
    items: [
      { title: "general.company", key: "executorCompanyName" },
      { title: "general.responsible", key: "executedBy", event: "hover" },
    ],
  },
];

const gebouwnummer = [
  {
    title: "general.objectAddress",
    key: "objectadres",
    description: "Weena 8",
  },
  { title: "general.address.city", key: "plaats", description: "3012 CM" },
  { title: "general.location", key: "locatie", description: "ROTTERDAM" },
];

function BasicDetails({
  isSecondSignatureSurvey,
  hideSignatureFromPDF,
  showMeldingImage,
  data: selectedInspection,
  hidePDFShare,
  onlyOfflinePDFView,
  showPdfIcon,
  setIsOpenSignature = () => {},
  isInspectionInProgress = false,
  ...props
}) {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation();
  const [popover, setPopover] = useState({ element: null, data: null });
  const [timeOutId, setTimeOutId] = useState(null);
  const [buildingNumber, setBuildingNumber] = React.useState({
    element: null,
    data: null,
  });
  const isCustomMediumScreen = useMediaQuery(
    theme.breakpoints.between(960, 1238)
  );
  const [readOnly, setReadOnly] = useState(false);
  const [reportPopOverEl, setReportPopOverEl] = useState(null);
  const [isOpenDocViewer, setISOpenDocViewer] = useState(false);
  const [previewDocument, setPreviewDocument] = useState([]);
  const [pdfMenuLoading, setPDFMenuLoading] = useState(false);
  const pdfReport = usePDFReport(
    isSecondSignatureSurvey,
    hideSignatureFromPDF,
    showMeldingImage
  );
  const inspectionStatus =
    selectedInspection &&
    (isSecondSignatureSurvey
      ? !selectedInspection.isSecondSignatureInitiated
        ? 0
        : selectedInspection.secondSignatureDate
        ? selectedInspection.status
        : 1
      : selectedInspection.status);
  const [openDatePicker, setOpenDatePicker] = useState(false);
  const dispatch = useDispatch();
  const canLockedInspection = useCanLockedInspection();
  const app = useSelector((state) => state.app);
  const { user } = useSelector((state) => state.authentication);
  const isOnline = useInternetStatusDetector();
  const isBuyer = user && user.type === userAccountTypeConstants.buyer;

  useEffect(() => {
    if (selectedInspection) {
      setReadOnly(isReadOnly(selectedInspection.status));
    }
  }, [selectedInspection && selectedInspection.status]);

  const buildingNumberHandleClose = () => {
    clearTimeout(timeOutId);
    setBuildingNumber({ element: null, data: null });
  };

  const open = Boolean(buildingNumber);
  const id = open ? "simple-popover" : undefined;

  const handlePopoverOpen = ({ currentTarget }, event) => {
    clearTimeout(timeOutId);
    const id = setTimeout(() => {
      const { executorDataRelation, executorDataPerson } = selectedInspection;
      const data = {
        userInformation: executorDataPerson
          ? {
              ...executorDataPerson,
              isPerson: true,
            }
          : executorDataRelation,
        event,
      };
      setPopover({ element: currentTarget, data });
    }, [150]);
    setTimeOutId(id);
  };

  const handlePopoverClose = () => {
    clearTimeout(timeOutId);
    setPopover({ element: null, data: null });
  };

  const pdfDownloadHandler = () => {
    setPDFMenuLoading("download");
    if (onlyOfflinePDFView || !window.navigator.onLine) {
      pdf(pdfReport)
        .toBlob()
        .then(async (blob) => {
          const url = window.URL.createObjectURL(blob);
          let link = document.createElement("a");
          link.href = url;
          link.download =
            (selectedInspection.surveyType === SurveyType.Delivery
              ? isSecondSignatureSurvey
                ? "PvO_met_tweede_handtekening_"
                : "PvO_"
              : selectedInspection.surveyType === SurveyType.PreDelivery ? (t("survey.preDelivery") + "_") : (t("survey.inspection") + "_")) +
            formatDate(new Date()) +
            "_" +
            new Date().getTime() +
            ".pdf";
          document.body.appendChild(link).click();
          setTimeout(() => window.URL.revokeObjectURL(url), 200);
          setPDFMenuLoading(false);
        })
        .catch((er) => {
          console.log(er, "er");
          setPDFMenuLoading(false);
        });
    }
  };

  const pdfShowHandler = () => {
    setPDFMenuLoading("view");
    if (onlyOfflinePDFView || !window.navigator.onLine) {
      pdf(pdfReport)
        .toBlob()
        .then(async (blob) => {
          const url = await window.URL.createObjectURL(blob);
          setPreviewDocument([
            {
              uri: url,
              fileType: "application/pdf",
              name:
                (selectedInspection.surveyType === SurveyType.Delivery
                  ? isSecondSignatureSurvey
                    ? "PvO_met_tweede_handtekening_"
                    : "PvO_"
                  : selectedInspection.surveyType === SurveyType.PreDelivery ? (t("survey.preDelivery") + "_") : (t("survey.inspection") + "_")) +
                formatDate(new Date()) +
                "_" +
                new Date().getTime() +
                ".pdf",
            },
          ]);
          setISOpenDocViewer(true);
          setPDFMenuLoading(false);
        })
        .catch((er) => {
          console.log(er, "er");
          setPDFMenuLoading(false);
        });
    } else {
      setPreviewDocument([
        {
          uri:
            URLS[
              isSecondSignatureSurvey
                ? "GET_SURVEY_REPORT_FOR_SECOND_SIGNATURE"
                : "GET_SURVEY_REPORT"
            ] + selectedInspection.surveyId,
          fileType: getMimeTypefromString(".pdf"),
          name: selectedInspection.buildingNoIntern,
        },
      ]);
      setISOpenDocViewer(true);
      setPDFMenuLoading(false);
    }
  };

  const updateInspectionDate = async (date) => {
    await dispatch(
      surveyActions.updateSurvey(
        { ...selectedInspection, date, surveyId: selectedInspection.surveyId },
        selectedInspection.surveyType
      )
    );
  };

  return (
    <Paper className={classes.detailMainContainer}>
      <MobileDatePicker
        open={openDatePicker}
        onClose={() => setOpenDatePicker(false)}
        value={selectedInspection && new Date(selectedInspection.date)}
        onChange={(date) => updateInspectionDate(date)}
        slotProps={{
          textField: {
            variant: "standard",
            size: "0",
            style: { display: "none" },
          },
        }}
      />
      {selectedInspection && (
        <>
          <div className={classes.detailMainHeader}>
            <Typography className={classes.detailHeader}> {t("wkb.basicData")}</Typography>
            <div>
              {showPdfIcon && selectedInspection.status !== 0 && (isSecondSignatureSurvey ? selectedInspection.isSecondSignatureInitiated && selectedInspection.secondSignatureDate : isReadOnly(selectedInspection.status)) && (
                <IconButton onClick={(e) => setReportPopOverEl(e.currentTarget)} className={classes.signButton} variant="outlined" size="large">
                  <PictureAsPdf style={{ fill: red[800] }} />
                </IconButton>
              )}
              <Chip label={t(SurveyStatus[inspectionStatus])} className={classes.detailChip} />
            </div>
          </div>
          <div className={classes.detailContainer}>
            <Grid container spacing={1} className={classes.detailsContain}>
              {DASHBOARD_KEYS.map((x) => (
                <Grid item xs={6} sm={6} md={isCustomMediumScreen ? 6 : 3} lg={isCustomMediumScreen ? 6 : 3} xl={3} className={classes.detailSubContainer}>
                  <Paper className={classes.detailPaper}>
                    {x.items.map((p, i) => {
                      const openExecutorInfo =
                        p.key === "executedBy" &&
                        isOnline &&
                        (app === apps.aftercare || app === apps.constructionQuality) &&
                        !selectedInspection.lockId &&
                        !(isSecondSignatureSurvey ? selectedInspection.secondSignatureDate : isCompletedSurvey(selectedInspection.status)) &&
                        (selectedInspection.surveyType === SurveyType.Inspection || selectedInspection.surveyType === SurveyType.PreDelivery || selectedInspection.surveyType === SurveyType.Delivery)

                      const cardProps =
                        p.event === "hover"
                          ? {
                              "aria-owns": !!popover ? "mouse-over-popover" : undefined,
                              "aria-haspopup": "true",
                              onMouseOver: (e) => {
                                if (openExecutorInfo) {
                                  handlePopoverOpen(e, p.event)
                                }
                              },
                              onMouseOut: () => clearTimeout(timeOutId),
                            }
                          : {}
                      return (
                        <>
                          <div className={classes.detailInnerPapper}>
                            <Typography className={classes.detailText}>{t(p.title)}:</Typography>
                            <div className={clsx(classes.detailSubItem, openExecutorInfo && classes.dottedBottomBorder)}>
                              <Tooltip
                                title={p.key === "executedBy" ? "" : p.key === "date" ? formatDate(new Date(selectedInspection[p.key])) : p.key === "status" ? t(SurveyStatus[inspectionStatus]) : t(selectedInspection[p.key])}
                                enterTouchDelay={0}
                              >
                                <Typography className={classes.detailSubText} {...cardProps}>
                                  {p.key === "date" ? formatDate(new Date(selectedInspection[p.key])) : p.key === "status" ? t(SurveyStatus[inspectionStatus]) : t(selectedInspection[p.key])}
                                </Typography>
                              </Tooltip>

                              {!readOnly && p.Icon && isInspectionInProgress && (
                                <p.Icon
                                  onClick={(e) => {
                                    if (readOnly) return
                                    setOpenDatePicker(true)
                                  }}
                                  className={classes.detailEditIcon}
                                />
                              )}
                            </div>
                          </div>
                          {!i && <Divider className={classes.divider} />}
                        </>
                      )
                    })}
                  </Paper>
                </Grid>
              ))}
              <UserInformation
                isSecondSignature={isSecondSignatureSurvey}
                customTitle={"general.responsible"}
                repairRequestRight={false}
                changeExecutorForSurvey
                projectId={selectedInspection && selectedInspection.projectId}
                isEditable={!isBuyer}
                surveyData={selectedInspection}
                data={popover.data}
                open={!!popover.element}
                anchorEl={popover.element}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                hideExtraInfo
                onClose={handlePopoverClose}
              />
              <Popover
                id={id}
                className={classes.buildingPopovers}
                open={!!buildingNumber.element}
                anchorEl={buildingNumber.element}
                onClose={buildingNumberHandleClose}
                disableRestoreFocus
                anchorOrigin={{
                  vertical: "center",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
              >
                <div className={classes.buildingPopoverContainer} onMouseLeave={popover.data && popover.data.event === "hover" && !buildingNumber.element && buildingNumberHandleClose}>
                  <Grid container className={classes.buildingContainer}>
                    {gebouwnummer.map((p) => {
                      return (
                        <>
                          <Grid item xs={6}>
                            <Typography className={classes.buildingTitle}>{t(`${p.title}`)}:</Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className={classes.buildingDescription}>{t(`${p.description}`)}</Typography>
                          </Grid>
                        </>
                      )
                    })}
                  </Grid>
                </div>
              </Popover>
            </Grid>
          </div>
        </>
      )}
      <DocumentViewer
        radius={classes.docRadius}
        isSurveying
        open={isOpenDocViewer}
        onClose={() => {
          setISOpenDocViewer(false)
          setPreviewDocument([])
        }}
        documents={previewDocument}
      />

      {selectedInspection && showPdfIcon && (
        <Menu
          id="simple-menu"
          className={classes.menuContent}
          anchorEl={reportPopOverEl}
          open={!!reportPopOverEl}
          onClose={() => setReportPopOverEl(null)}
          keepMounted
          elevation={1}
          getContentAnchorEl={null}
          disableRestoreFocus
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MenuList className={classes.menuListStyle}>
            {onlyOfflinePDFView || !window.navigator.onLine ? (
              <StyledMenuItem disabled={pdfMenuLoading === "download"} onClick={pdfDownloadHandler}>
                <ListItemIcon>{pdfMenuLoading === "download" ? <CircularProgress size={18} style={{ minWidth: 0 }} color="primary" /> : <CloudDownload className={classes.menuIcon} color={"primary"} />}</ListItemIcon>
                <Typography variant="inherit">{t("general.download")}</Typography>
              </StyledMenuItem>
            ) : (
              <StyledMenuItem component={"a"} href={URLS[isSecondSignatureSurvey ? "GET_SURVEY_REPORT_FOR_SECOND_SIGNATURE" : "GET_SURVEY_REPORT"] + selectedInspection.surveyId} download>
                <ListItemIcon>
                  <CloudDownload className={classes.menuIcon} color={"primary"} />
                </ListItemIcon>
                <Typography variant="inherit">{t("general.download")}</Typography>
              </StyledMenuItem>
            )}
            <StyledMenuItem disabled={pdfMenuLoading === "view"} onClick={pdfShowHandler}>
              <ListItemIcon>{pdfMenuLoading === "view" ? <CircularProgress size={18} style={{ minWidth: 0 }} color="primary" /> : <Visibility className={classes.menuIcon} color={"primary"} />}</ListItemIcon>
              <Typography variant="inherit">{t("general.show")}</Typography>
            </StyledMenuItem>
            {!hidePDFShare && !canLockedInspection && (
              <StyledMenuItem
                onClick={() => {
                  setIsOpenSignature(true)
                }}
              >
                <ListItemIcon>
                  <Send className={classes.menuIcon} color={"primary"} />
                </ListItemIcon>
                <Typography variant="inherit">{t("general.send")}</Typography>
              </StyledMenuItem>
            )}
          </MenuList>
        </Menu>
      )}
    </Paper>
  )
}

const useStyles = makeStyles((theme) => ({
  "@global": {
    ".MuiButtonBase-root:focus": {
      outline: 0,
    },
  },
  menuListStyle: {
    padding: theme.spacing(1.875, 1.25),
    boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px",
    borderRadius: 10,
    width: 167,
  },
  menuIcon: {
    marginRight: 10,
  },
  menuContent: {
    "& .MuiPopover-paper": {
      borderRadius: 10,
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important",
    },
    "& ul": {
      padding: theme.spacing(0),
    },
    "& ul > ul": {
      padding: theme.spacing(0.5, 2),
    },
  },
  detailSubItem: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    textOverflow: "ellipsis",
    // display: "-webkit-box",
    "-webkitLineClamp": 1,
    "-webkitBoxOrient": "vertical",
  },
  detailsContain: {
    flexGrow: 1,
  },
  detailSubContainer: {
    [theme.breakpoints.down("sm")]: {
      padding: `5px !important`,
    },
  },
  detailChip: {
    height: 25,
    fontSize: 12,
    [theme.breakpoints.only("xs")]: {
      padding: theme.spacing(1.2, 1),
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    padding: theme.spacing(0),
  },
  detailMainHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(0.625, 1.2, 0.625, 1.2),
    },
    padding: theme.spacing(0.625, 2, 0.625, 2),
    minHeight: 57,
  },
  buildingPopovers: {
    "&  .MuiPopover-paper": {
      boxShadow: "rgb(100 100 111 / 20%) 0px 7px 29px 0px !important",
      borderRadius: 5,
    },
  },

  buildingContainer: {
    rowGap: "10px",
  },
  buildingTitle: {
    fontSize: 11,
    color: theme.palette.common.black,
    fontWeight: "bold",
  },
  buildingDescription: {
    fontSize: 11,
    color: theme.palette.grey[700],
    fontWeight: "normal",
  },
  buildingPopoverContainer: {
    padding: theme.spacing(1.4),
    maxWidth: "171px",
  },
  detailEditIcon: {
    alignSelf: "center",
    color: theme.palette.grey[500],
    cursor: "pointer",
    fontSize: 18,
    marginLeft: 8,
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
      right: 0,
      top: 10,
    },
  },
  detailText: {
    fontSize: 12,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    color: theme.palette.common.black,
    fontWeight: "bold",
    whiteSpace: "nowrap",
    paddingRight: theme.spacing(2),
  },
  detailSubText: {
    fontSize: 12,
    [theme.breakpoints.only("xs")]: {
      fontSize: 14,
    },
    [theme.breakpoints.only("sm")]: {
      fontSize: 16,
    },
    color: theme.palette.grey[600],
    wordBreak: "break-all",
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkitLineClamp": 1,
    "-webkitBoxOrient": "vertical",
  },
  dateIcon: {
    fill: theme.palette.grey[600],
    fontSize: 18,
  },
  detailPaper: {
    height: 70,
    [theme.breakpoints.down("sm")]: {
      height: 110,
    },
    borderLeft: `2px solid ${theme.palette.primary.main}`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    background: alpha(theme.palette.primary.main, 0.05),
    boxShadow: "none",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
  },
  detailInnerPapper: {
    position: "relative",
    display: "flex",
    // flexDirection: 'column',
    justifyContent: "space-between",
    flexGrow: 1,
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      height: "100%",
    },
  },
  divider: {
    [theme.breakpoints.down("sm")]: {
      margin: "6px 0",
    },
  },
  detailContainer: {
    padding: theme.spacing(0, 2, 2, 2),
  },
  detailMainContainer: {
    boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;",
  },
  detailHeader: {
    fontSize: 16,
    color: theme.palette.grey[800],
    fontWeight: "bold",
  },
  dottedBottomBorder: {
    borderBottom: `1px dotted ${theme.palette.grey[600]}`,
    cursor: "default",
  },
}));

export default React.memo(BasicDetails, (prev, curr) => {
  return prev && curr && prev.data === curr.data;
});
